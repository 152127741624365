import React from 'react';
import ReactDOM from 'react-dom';
import { i18n, init as i18nInit } from 'src/i18n';
import { AuthToken } from './modules/auth/authToken';
import TenantService from './modules/tenant/tenantService';
import SettingsService from './modules/settings/settingsService';
import AuthService from 'src/modules/auth/authService';

import './tw.scss';
import { createRoot } from 'react-dom/client';




(async function () {
  const isSocialOnboardRequested = AuthService.isSocialOnboardRequested();
  const isWorkosOnboardRequested = AuthService.isWorkosOnboardRequested();
  
  AuthToken.applyFromLocationUrlIfExists();
  await TenantService.fetchAndApply();
  if (isWorkosOnboardRequested) {
    await AuthService.workosOnboard();
  } else if (isSocialOnboardRequested) {
    await AuthService.socialOnboard();
  }
  SettingsService.applyThemeFromTenant();
  await i18nInit();

  const { default: App } = await import('./App');

  document.title = i18n('app.title');
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App />);
})();


console.log = function () { }
console.info = function () { }
console.debug = function () { }
console.warn = function () { }
console.error = function () { }
console.clear = function () { }
console.count = function () { }
console.countReset = function () { }
console.assert = function () { }
console.dir = function () { }
console.dirxml = function () { }
console.group = function () { }
console.groupCollapsed = function () { }
console.groupEnd = function () { }
console.table = function () { }
console.time = function () { }
console.timeEnd = function () { }
console.timeLog = function () { }
console.trace = function () { } 