//const config = (await import(/* @vite-ignore */ `./${process.env.REACT_APP_ENVIRONMENT}`)).default;
//export default config;


const config = {
    backendUrl: import.meta.env.VITE_BACKEND_URL,
    consumerWorkspaceId: import.meta.env.VITE_CONSUMER_WORKSPACE_ID,
    apiDocumentationUrl: import.meta.env.VITE_API_DOCUMENTATION_URL,
    frontendUrl: {
        host: import.meta.env.VITE_FRONTEND_URL_HOST,
        protocol: import.meta.env.VITE_FRONTEND_URL_PROTOCOL,
    },
    tenantMode: import.meta.env.VITE_TENANT_MODE,
    isPlanEnabled: import.meta.env.VITE_IS_PLAN_ENABLED === 'true',
    stripePublishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    production: import.meta.env.VITE_BACKEND_URL.includes('https'),
};




export default config;